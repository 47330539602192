/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";

import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getAllOrders, getOrderStatus,updateStatus, getSortedOrders } from "../../../slices/OrderSlices.js";
import Loader from "components/loader/loader.js";
import NotificationAlert from "react-notification-alert";
import "../order-list/Order.css";
import ReactTable from "components/ReactTable/ReactTable.js"

function Orders() {
  const notificationAlert = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const ordersList = useSelector((state) => state.order.orders);
  const orderStatusList = useSelector((state) => state.order.orderStatus);
  const [status, setStatus] = React.useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [dataState, setDataState] = React.useState([]);

  useEffect(() => {
    if (ordersList && ordersList.length > 0) {
      setDataState(
        ordersList.map((order, index) => {
          return {
            id:order.id,
            sNo: index+1,
            orderNo: order.orderNo,
            orderDate: order.orderDate,
            customerName: order.customerName,
            totalPrice: order.totalAmount,
            status: order.status,
            actions: (
              <div className="center">
              <Link
              to="/admin/view-order"
              state={{ orderId: order.id }}
            >
              <Button
                className="btn-round btn-icon"
                outline
                color="primary"
                id={`tooltipView${index+1}`}
                size="sm"
              >
                <i className="nc-icon nc-alert-circle-i" />
              </Button>
            </Link>{" "}
            <UncontrolledTooltip
              delay={0}
              target={`tooltipView${index+1}`}
            >
              View
            </UncontrolledTooltip>
            </div>
            ),
          };
        })
      );
    }
  }, [ordersList]);

  useEffect(() => {
    listAllOrders();
  }, [dispatch]);

  const listAllOrders = async () => {
    setIsLoading(true);
    const res = await dispatch(getAllOrders());
     const ress  = await dispatch(getOrderStatus());
     if(res.error || ress.error) {
      setIsLoading(false);    
      validate(`Something went wrong`,"Please try again later");
    }
    else {
    setIsLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "NEW":
        return "primary";
      case "IN PROGRESS":
        return "warning";
      case "REJECTED":
        return "danger";
      case "CANCELLED":
        return "default";
      case "DELIVERED":
        return "success";
      default:
        return "info";
    }
  };

  const editStatus = (index,status) => {
    setStatus(status)
    setEditIndex(index);
  };

  const statusUpdate = (id,selectedStatus) => {
    if (id > 0) {
      if(selectedStatus != status) {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => changeStatus(id)}
            onCancel={() => cancelDetele()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, Change it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            Your status will update in list!
          </ReactBSAlert>
        );
      }
      else{
        validate("Alert !!","Please Change the Status");
      }
    }
  }

  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
          <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const changeStatus = async (id) => {
    let formData = new FormData();
        formData.append("id", id);
        formData.append("status", status);
        setIsLoading(true);    
       const res = await dispatch(updateStatus(formData));
       if(res.error) {
        setIsLoading(false);    
        validate(`Something went wrong`,"Please try again later");
        hideAlert();
      }
      else {
        setDataState((prevState) => prevState.map((order) => order.id === id ? { ...order, status: status } : order));
        setIsLoading(false);
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Updated!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Your status has been Updated.
          </ReactBSAlert>
        );
      }
  };

  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data file is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setEditIndex(null);
    setAlert(null);
  };

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = async (buttonId) => {
    setActiveButton((prevActiveButton) => (prevActiveButton === buttonId ? null : buttonId));
    if(buttonId == 1) {
      setIsLoading(true);
      await dispatch(getSortedOrders("S"));
      setIsLoading(false);
    }
    else {
      setIsLoading(true);
      await dispatch(getSortedOrders("D"));
      setIsLoading(false);
    }
  };

  const getStatusBadge = (status) => (
    <Badge color={getStatusColor(status)} pill>{status}</Badge>
    );

  return (
    <>
        <NotificationAlert ref={notificationAlert} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          {alert}
          <Row>
            <Col md="12"> 
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">Order Table</CardTitle>
                    </Col>
                  </Row>
               </CardHeader>
                <CardBody>
                  
                <ReactTable
                  data={dataState}
                  columns={[
                    {
                      Header: "#",
                      accessor: "sNo",
                    },
                    {
                      Header: "Order No",
                      accessor: "orderNo",
                    },
                    {
                      Header: "Order Date",
                      accessor: "orderDate",
                    },
                    {
                      Header: "Customer Name",
                      accessor: "customerName",
                    },
                    {
                      Header: "Total Price",
                      accessor: "totalPrice",
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      Cell: ({ value, row }) =>
                        editIndex === row.index ? (
                          <>
                            <Row >
                              <Select
                                className="react-select primary custom-drop"
                                classNamePrefix="react-select"
                                name="status"
                                menuPosition="fixed" 
                                onChange={(selectedStatus) =>
                                  setStatus(selectedStatus.label)
                                }
                                value={
                                  status
                                    ? { value: status, label: status }
                                    : null
                                }
                                options={[
                                  {
                                    value: "",
                                    label: "Please Select Status",
                                    isDisabled: true,
                                  },
                                  ...orderStatusList.map((status) => ({
                                    value: status.id,
                                    label: status.status,
                                  })),
                                ]}
                                placeholder="Please Select Status"
                              />
                              <div className="mt-2">
                                <span
                                  id={`tooltipUpdate${row.index}`}
                                  className="ml-2"
                                  onClick={(e) =>
                                    statusUpdate(row.original.id, row.original.status)
                                  }
                                >
                                  <i className="nc-icon nc-check-2" />
                                </span>
                                <span
                                  className="ml-2"
                                  id={`tooltipCancel${row.index}`}
                                  onClick={() => editStatus(null, row.original.status)}
                                >
                                  <i className="nc-icon nc-simple-remove" />
                                </span>
                              </div>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipUpdate${row.index}`}
                              >
                                Update
                              </UncontrolledTooltip>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipCancel${row.index}`}
                              >
                                Cancel
                              </UncontrolledTooltip>
                            </Row>
                          </>
                        ) : (
                          <>
                            {getStatusBadge(value)}
                            <span
                              className="ml-2"
                              id={`tooltipEditStatus${row.index}`}
                              onClick={() => editStatus(row.index, value)}
                            >
                              <i className="fa fa-edit" />
                            </span>
                            <UncontrolledTooltip
                              delay={0}
                              target={`tooltipEditStatus${row.index}`}
                            >
                              Edit
                            </UncontrolledTooltip>
                          </>
                        ),
                    },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  className=" primary-pagination"
                />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default Orders;

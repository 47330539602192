import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductService from "../service/ProductService.js";


export const addProduct = createAsyncThunk(
  "addProduct",
  async (formData) => {
    try{
      const res = await ProductService.add(formData);
      return res.data;
    }
    catch(error) {
      console.error("Error adding product:", error);
      throw error;
    }
  }
);

export const getAllProduct = createAsyncThunk(
  "getAllProduct",
  async () => {
    try {
      const res = await ProductService.getAll();
      const response = res;
      return response.data;
    } catch (error) {
      console.error("Error fetching all product:", error);
      throw error;
    }
  }
);

export const getAllActiveProduct = createAsyncThunk(
  "getAllActiveProduct",
  async () => {
    try {
      const res = await ProductService.getAllActive();
      const response = res;
      return response.data;
    } catch (error) {
      console.error("Error fetching active product:", error);
      throw error;
    }
  }
);

export const updateProduct = createAsyncThunk(
  "updateProduct",
  async (formData) => {
    try{
      const res = await ProductService.update(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error updating product:", error);
      throw error;
    }
  }
);

export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (data) => {
    try{
      const res = await ProductService.updateStatus(data);
      return res.data;
    }
    catch (error) {
      console.error("Error updating status:", error);
      throw error;
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "deleteProduct",
  async (id) => {
    try{
      await ProductService.delete(id);
      return  id;
    }
    catch (error) {
      console.error("Error deleting product:", error);
      throw error;
    }
  }
);


const productSlice = createSlice({
  name: "product",
  initialState: {
    products:[],
    status:'idle',
    error:null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
    .addCase(getAllProduct.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getAllProduct.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.products = action.payload;
    })
    .addCase(getAllProduct.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(getAllActiveProduct.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getAllActiveProduct.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.products = action.payload;
    })
    .addCase(getAllActiveProduct.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })

    .addCase(addProduct.fulfilled, (state, action) => {
      state.products.push(action.payload);
    })

    .addCase(updateProduct.fulfilled, (state, action) => {
      const updatedProduct = action.payload;
      const index = state.products.findIndex(product => product.id === updatedProduct.id);
      if (index !== -1) {
        state.products[index] = updatedProduct;
      }    })
    .addCase(updateStatus.fulfilled, (state, action) => {
      const updatedProduct = action.payload;
      const index = state.products.findIndex(product => product.id === updatedProduct.id);
      if (index !== -1) {
        state.products[index] = updatedProduct;
      }    })
    .addCase(deleteProduct.fulfilled, (state, action) => {
      const deletedProductId = action.payload;
      state.products = state.products.filter((product) => product.id !== deletedProductId);
    });
  
  },
});

const { reducer } = productSlice;
export default reducer;

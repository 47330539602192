import React from "react";
import styled from "styled-components";

const FileInputButton = styled.input.attrs({
  type: "file",
})`
  &::file-selector-button {
    margin-right: 20px;
    border: none;
    background: grey;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
  }

  &:hover::file-selector-button {
    background: grey;
  }

  /* Add border styles for the input */
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;
`;

export default FileInputButton;

import React, { useEffect, useState } from "react";
import {
  ModalHeader,
  ModalBody,
  Modal,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";
import CategoryService from "views/service/CategoryService";
import Loader from "components/loader/loader.js";
import ProductService from "views/service/ProductService";

function ImageModal({ isOpen, toggle, obj }) {

  const initialCategoryState = {
    id: 0,
    categoryName: "",
    tag: "",
  };

  const initialProductImageState = {
    productImageResponseList: [],
    productName: "",
    tag: "",
  };

  const [category, setCategory] = useState(initialCategoryState);
  const [product, setProduct] = useState(initialProductImageState);
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (obj.tag === "C") {
      setCategory(obj);
    }
    if (obj.tag === "P") {
      setProduct(obj);
    }
  }, [obj]);

  useEffect(() => {
    if (obj.tag === "C") {
      showCategoryImage(category.id);
    }
    if (obj.tag === "P") {
      showProductImages(product.productImageResponseList);
    }
  }, [category,product]);

  const showCategoryImage = async (id) => {
    if (id > 0 && category.tag === "C") {
      setIsLoading(true);
      try {
        const res = await CategoryService.getImageById(id);
        const blob = new Blob([res.data], { type: "image/*" });
        const dataUrl = URL.createObjectURL(blob);
        setImages([dataUrl]);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching image:", error);
        setIsLoading(false);
      }
    }
  };

  const showProductImages = async (productImageResponseList) => {
    if (productImageResponseList && productImageResponseList.length > 0) {
      setIsLoading(true);
      const promises = productImageResponseList.map(async (image) => {
        try {
          const res = await ProductService.getImageById(image.id);
          const blob = new Blob([res.data], { type: "image/*" });
          const dataUrl = URL.createObjectURL(blob);
          return { dataUrl, primaryImage: image.primaryImage };
        } catch (error) {
          console.error("Error fetching image:", error);
          return null;
        }
      });
  
      Promise.all(promises)
        .then((imageDataList) => {
          const primaryImage = imageDataList.find((imageData) => imageData.primaryImage);
          const secondaryImages = imageDataList.filter((imageData) => !imageData.primaryImage);
          const sortedImages = primaryImage ? [primaryImage, ...secondaryImages] : secondaryImages;
  
          setImages(sortedImages.map((imageData) => imageData.dataUrl));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
          setIsLoading(false);
        });
    }
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = images.map((image, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <img src={image} alt={obj.tag === "C" ? category.categoryName : product.productName} style={{ width: '100%', maxHeight: '400px' }}/>
      <CarouselCaption captionText="" captionHeader="" />
    </CarouselItem>
  ));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>{obj.tag === "C" ? `${category.categoryName}` : `${product.productName}`}</ModalHeader>
          <ModalBody>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={false}
            >
              <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {slides}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}

export default ImageModal;

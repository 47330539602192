import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardService from "../service/DashboardService.js";


export const getAlldata = createAsyncThunk(
  "getAlldata",
  async () => {
    try {
      const res = await DashboardService.getAlldata()
      return res.data;
    }
    catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
);



const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboards: [],
    status: 'idle',
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAlldata.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAlldata.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dashboards = action.payload;
      })
      .addCase(getAlldata.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
  },
});

const { reducer } = dashboardSlice;
export default reducer;

import http from "../../Environment.js";

class ContactUsService {
    
  getAll() {
    return http.get("/contact-us");
  }

  getById(id) {
    return http.get(`/contact-us/${id}`);
  }

  add(data) {
    return http.post("/contact-us", data);
  }

  update(data) {
    return http.put("/contact-us", data);
  }

  delete(id) {
    return http.delete(`/contact-us/${id}`);
  }
}

export default new ContactUsService();
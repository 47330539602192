/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState }  from "react";
import { useDispatch } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import { useNavigate  } from 'react-router-dom';
import Loader from 'components/loader/loader.js';
import { login } from "../../slices/LoginSlices.js";
import NotificationAlert from "react-notification-alert";


function Login() {

  const initialUserState = {
    username: "",
    password: "",
  };

  const dispatch = useDispatch();
  const notificationAlert = React.useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [user, setUser] = useState(initialUserState);


  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const submit = async () =>{
    const isValidUsername =user.username !==null && user.username !== "" ;
    const isValidPassword =user.password !==null && user.password !== "";
    if(isValidUsername && isValidPassword) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("username", user.username);
      formData.append("password", user.password);
      const res = await dispatch(login(formData));
      if(res.error) {
        setIsLoading(false);    
        validate("Alert","Enter valid username or password");
      }
      else {
        setIsLoading(false);
        setSubmitted(true);
        setToken(res.payload);
        navigate("../../admin/dashboard");
        window.location.reload();
      }
    }
    else {
      validate("Alert","Enter valid username or password");
    }
  }

  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
          <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  const setToken = (res) => {
    localStorage.setItem("token", res.token);
    localStorage.setItem("status", "T");
  }

  return (
    <>
       <div className="login-page">
         <NotificationAlert ref={notificationAlert} />
     {isLoading ? (
        <Loader />
      ) : (
        <>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form action="" className="form" method="">
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Login</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="username..." type="text" onChange={(e) => {setUser({...user,username: e.target.value,});}} />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="password" type="password" autoComplete="off" onChange={(e) => {setUser({...user,password: e.target.value,});}}/>
                      </InputGroup>
                      <br />
                      <FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input  defaultValue="" type="checkbox" />
                            <span className="form-check-sign" />
                            remember me
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                        <Button block className="btn-round mb-3" color="warning" outline onClick={submit}> Get Started</Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`,
            }}
          />
          </>
          )};
        </div>
    </>
  );
}

export default Login;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LoginService from "../service/LoginService.js";

export const login = createAsyncThunk(
    "login",
    async (formData) => {
      try {
       const res= await LoginService.login(formData)
       return res.data;
      }
      catch (error) {
        console.error("Error checking user:", error);
        throw error;
      }
    }
  );

  export const logout = createAsyncThunk("auth/logout", async () => {
    try {
      await LoginService.logout();
    }
    catch (error) {
      console.error("Error checking user:", error);
      throw error;
    }
  });

  const authSlice = createSlice({
    name: "logins",
    initialState: {
      auths: [],
      status: 'idle',
      error: null,
    },
    reducers: {},
  
    extraReducers: (builder) => {
      builder
        .addCase(login.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.auth = action.payload;
        })  
        .addCase(logout.fulfilled, (state, action) => {
          state.auth =null;
        })  
    },
  });
  
  const { reducer } = authSlice;
  export default reducer;
/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect,useState }  from "react";
import { useDispatch } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import Loader from 'components/loader/loader.js';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { addContact,updateContact } from "../../slices/ContactSlices.js";
import  ContactService  from "../../service/ContactService.js";
import { useLocation } from "react-router-dom";

function AddContact() {

  const initialContactState = {
    id: null,
    name: "",
    email: "",
    mobileNumber: "",
    address: "",
    pincode: "",
    district: "",
    state: "",
    status:"I",
  };

  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const contactId = location.state ? location.state.contactId : 0;
  const isEdit = contactId > 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState(null); 
  const [contact, setContact] = useState(initialContactState);
  const [nameState, setNameState] = React.useState("");
  const [emailState, setEmailState] = React.useState("");
  const [mobileNumberState, setMobileNumberState] = React.useState("");
  const [addressState, setAddressState] = React.useState("");
  const [pincodeState, setPincodeState] = React.useState("");
  const [districtState, setDistrictState] = React.useState("");
  const [stateState, setStateState] = React.useState("");
  const [statusState, setStatusState] = React.useState("");

  useEffect(() => {
      fetchContactData(); 
  }, [isEdit,contactId,dispatch]);

  const fetchContactData = async () => {
    if (isEdit) {
      try {
        setIsLoading(true);
        const res = await ContactService.getById(contactId);
        const data = res.data
        setContact({
          id: data.id,
          name: data.name,
          email: data.email,
          mobileNumber: data.mobileNumber,
          address: data.address,
          pincode: data.pincode,
          district: data.district,
          state: data.state,
          status: data.status,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching contact by ID:", error);
      }
    }
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const verifyEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const verifyMobileNumber = (value) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(value);
  };

  const submit = () => {
    if (nameState === "") {
      setNameState("has-danger");
    }
    else if (emailState === "") {
      setEmailState("has-danger");
    }
    else if (mobileNumberState === "") {
      setMobileNumberState("has-danger");
    }
    else if (addressState === "") {
      setAddressState("has-danger");
    }
    else if (pincodeState === "") {
      setPincodeState("has-danger");
    }
    else if (districtState === "") {
      setDistrictState("has-danger");
    }
    else if (stateState === "") {
      setStateState("has-danger");
    }
    else {
        saveContact();
    }
  };

  const saveContact = async () => {
    if (contactId > 0) {
      if (!contact.name) {
        setNameState("has-danger");
        return;
      }
      if (!contact.email) {
        setEmailState("has-danger");
        return;
      }
      if (!contact.mobileNumber) {
        setMobileNumberState("has-danger");
        return;
      }
      if (!contact.address) {
        setAddressState("has-danger");
        return;
      }
      if (!contact.pincode) {
        setPincodeState("has-danger");
        return;
      }
      if (!contact.district) {
        setDistrictState("has-danger");
        return;
      }
      if (!contact.state) {
        setStateState("has-danger");
        return;
      }
      setIsLoading(true);
      await dispatch(updateContact(contact));
      setIsLoading(false);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Saved!"
          onConfirm={() =>{
            hideAlert(),
            navigate("/admin/company-profile")
          } }
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          Your data has been updated successfully.
        </ReactBSAlert>
      );
    } else {
      if (!contact.name) {
        setNameState("has-danger");
        return;
      }
      if (!contact.email) {
        setEmailState("has-danger");
        return;
      }
      if (!contact.mobileNumber) {
        setMobileNumberState("has-danger");
        return;
      }
      if (!contact.address) {
        setAddressState("has-danger");
        return;
      }
      if (!contact.pincode) {
        setPincodeState("has-danger");
        return;
      }
      if (!contact.district) {
        setDistrictState("has-danger");
        return;
      }
      if (!contact.state) {
        setStateState("has-danger");
        return;
      }
      setIsLoading(true);
      await dispatch(addContact(contact));
      setIsLoading(false);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Saved!"
          onConfirm={() =>{
            hideAlert(),
            navigate("/admin/company-profile")
          } }
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="success"
          btnSize=""
        >
          Your data has been saved successfully.
        </ReactBSAlert>
      );
    }
  };

  const cancel = () => {
    navigate("/admin/company-profile");
  }

  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
      {alert}
        <Row>
          <Col md="9">
            <Form id="LoginValidation">
              <Card>  
                <CardHeader>
                  <CardTitle tag="h4">{isEdit ? "Edit" : "Add New"} Contact Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup className={`has-label ${nameState}`}>
                        <Label>Name *</Label>
                        <Input
                          name="name"
                          type="text"
                          value={contact.name}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)) {
                              setNameState("has-danger");
                            } else {
                              setNameState("has-success");
                            }
                            setContact({ ...contact, name: e.target.value })
                          }}
                          placeholder="Please Enter Product Name"
                        />
                        {nameState === "has-danger" ? (
                          <label className="error">Please Enter Your Name.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup className={`has-label ${emailState}`}>
                        <Label>Email *</Label>
                        <Input
                          name="email"
                          type="email"
                          value={contact.email}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)|| !verifyEmail(e.target.value)) {
                              setEmailState("has-danger");
                            } else {
                              setEmailState("has-success");
                            }
                            setContact({ ...contact, email: e.target.value })
                          }}
                          placeholder="Please Enter Your Mail Id"
                        />
                        {emailState === "has-danger" ? (
                          <label className="error">Please Enter Valid Mail Id.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className={`has-label ${mobileNumberState}`}>
                        <Label>Mobile Number *</Label>
                        <Input
                          name="mobileNumber"
                          type="text"
                          value={contact.mobileNumber}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)|| !verifyMobileNumber(e.target.value)) {
                              setMobileNumberState("has-danger");
                            } else {
                              setMobileNumberState("has-success");
                            }
                            setContact({ ...contact, mobileNumber: e.target.value })
                          }}
                          placeholder="Please Enter Mobile Number"
                        />
                        {mobileNumberState === "has-danger" ? (
                          <label className="error">Please Enter Valid Mobile Number.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className={`has-label ${addressState}`}>
                        <Label>Address *</Label>
                        <Input
                          name="address"
                          type="text"
                          value={contact.address}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)) {
                              setAddressState("has-danger");
                            } else {
                              setAddressState("has-success");
                            }
                            setContact({ ...contact, address: e.target.value })
                          }}
                          placeholder="Please Enter Your Address"
                        />
                        {addressState === "has-danger" ? (
                          <label className="error">Please Enter Valid Address.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup className={`has-label ${pincodeState}`}>
                        <Label>Pincode *</Label>
                        <Input
                          name="pincode"
                          type="text"
                          value={contact.pincode}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)) {
                              setPincodeState("has-danger");
                            }
                            else{
                              if (!verifyNumber(e.target.value)) {
                                setPincodeState("has-danger");
                              } else {
                                setPincodeState("has-success");
                              }
                            }
                            setContact({ ...contact, pincode: e.target.value })
                          }}
                          placeholder="Please Enter Your Pincode"
                        />
                        {pincodeState === "has-danger" ? (
                          <label className="error">Please Enter Valid Pincode.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className={`has-label ${districtState}`}>
                        <Label>District *</Label>
                        <Input
                          name="district"
                          type="text"
                          value={contact.district}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)) {
                              setDistrictState("has-danger");
                            } else {
                              setDistrictState("has-success");
                            }
                            setContact({ ...contact, district: e.target.value })
                          }}
                          placeholder="Please Enter Your District"
                        />
                        {districtState === "has-danger" ? (
                          <label className="error">Please Enter Valid District.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup className={`has-label ${stateState}`}>
                        <Label>State *</Label>
                        <Input
                          name="state"
                          type="text"
                          value={contact.state}
                          onChange={(e) => {
                            if (!verifyLength(e.target.value, 1)) {
                              setStateState("has-danger");
                            } else {
                              setStateState("has-success");
                            }
                            setContact({ ...contact, state: e.target.value })
                          }}
                          placeholder="Please Enter Your State"
                        />
                        {stateState === "has-danger" ? (
                          <label className="error">Please Enter Valid State.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup check className="mt-1 ml-1">
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={contact.status === "A"} 
                              onChange={() => {
                                const newStatus = contact.status === "A" ? "I" : "A";
                                setContact({ ...contact, status: newStatus });
                              }}
                            />
                            Mark As Active{" "}
                            <span className="form-check-sign" />
                          </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="category form-category">
                    * Required fields
                  </div>
                </CardBody>
                <CardFooter className="text-right">
                  <Row>
                    <Col md="12">
                      <Button className="btn-round" outline color="primary" onClick={submit}>
                      {isEdit ? "Update" : "Save"} 
                      </Button>{" "}
                        <Button className="btn-round" outline color="danger" onClick={cancel}>
                          Cancel
                        </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
      )};
    </>
  );
}

export default AddContact;

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import store from './store'
import { Provider } from 'react-redux'

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

const App = () => {
  const isAuthenticated = localStorage.getItem("token");

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* Use a render function to conditionally render components based on authentication */}
          <Route
            path="/auth/*"
            element={<AuthLayout />}
          />
          <Route
            path="/admin/*"
            element={
              isAuthenticated ? <AdminLayout /> : <Navigate to="/auth/login" replace />
            }
          />
          {/* Redirect to login for any other route */}
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

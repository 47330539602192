import http from "../../Environment.js";

class EnquiryService {
  getAll() {
    return http.get("/enquiry");
  }

  delete(id) {
    return http.delete(`/enquiry/${id}`);
  }
}

export default new EnquiryService();
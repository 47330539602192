/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  CardFooter,
} from "reactstrap";
import AddCategory from "./AddCategory.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  getAllCategory,
  deleteCategory,
  updateStatus,
} from "../../slices/CategorySlices.js";
import Loader from "components/loader/loader.js";
import ImageModal from "components/Modal/ImageModal.js";
import NotificationAlert from "react-notification-alert";

function CategoryList() {
  const initialCategoryModalState = {
    id: 0,
    categoryName: "",
    tag: "",
  };
  const notificationAlert = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryModal, setCategoryModal] = useState(initialCategoryModalState);
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);

  useEffect(() => {
    listAllCategory();
  }, [dispatch]);

  const listAllCategory = async () => {
    setIsLoading(true);
    const res = await dispatch(getAllCategory());
    if(res.error) {
      validate(`Something went wrong`,"Please try again later");
    }
    setIsLoading(false);
  };

  const successDelete = async (id) => {
    setIsLoading(true);
    const res = await dispatch(deleteCategory(id));
    if(res.error) {
      setIsLoading(false);
      validate(`Something went wrong`,"Please try again later");
      hideAlert();
    }
    else {
      setIsLoading(false);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlerts()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your data has been deleted.
        </ReactBSAlert>
      );
    }
   
  };

  const changeStatus = async (id, status) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("activeStatus", status);
    setIsLoading(true);
   const res = await dispatch(updateStatus(formData));
   if(res.error) {
    setIsLoading(false);    
    validate(`Something went wrong`,"Please try again later");
    hideAlert();
  }
  else {
    setIsLoading(false);
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Updated!"
        onConfirm={() => hideAlerts()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your status has been Updated.
      </ReactBSAlert>
    );
  };
}

  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data file is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(false);
  };
  
  const hideAlerts = () => {
    window.location.reload();
    setAlert(null);
  };

  const deleteCategoryById = (id) => {
    if (id > 0) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(id)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this data!
        </ReactBSAlert>
      );
    }
  };

  const toggleAddModal = (id) => {
    setCategoryId(id);
    setAddModalOpen(!isAddModalOpen);
  };
  const toggleImageModal = (name,id) => {
    setCategoryModal({
      id: id,
      categoryName: name,
      tag: "C",
    });
    setImageModalOpen(!isImageModalOpen);
  };

  const statusUpdate = (id, status) => {
    if (id > 0) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => changeStatus(id, status)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Change it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          Your status will update in list!
        </ReactBSAlert>
      );
    }
  };

 const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
            <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
        <NotificationAlert ref={notificationAlert} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          {alert}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <CardTitle tag="h4">Category Table</CardTitle>
                    </Col>
                    <Col sm="5">
                      <div className="pull-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          outline
                          onClick={() => toggleAddModal(0)}
                        >
                          <i className="nc-icon nc-simple-add" />
                          Add
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="table-full-width table-hover">
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center"></th>
                        <th className="text-center">Category Name</th>
                        <th className="text-center">Image</th>
                        <th className="text-center">Description</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.length > 0 ? (
                        categories.map((category, index) => (
                          <tr
                            key={index}
                            style={{
                              backgroundColor: category.active
                                ? ""
                                : "rgb(245 243 243)",
                            }}
                          >
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              <FormGroup check>
                                <Label check>
                                  <Input
                                    type="checkbox"
                                    checked={category.active}
                                    onChange={() => {
                                      const newStatus = !category.active;
                                      statusUpdate(category.id, newStatus);
                                    }}
                                  />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </td>
                            <td className="text-center">
                              {category.categoryName}
                            </td>
                            <td className="text-center">
                              <i
                                className="fa fa-image"
                                onClick={() =>
                                  toggleImageModal(category.categoryName,category.id)
                                }
                              ></i>
                            </td>
                            <td className="text-center">
                              {category.description}
                            </td>
                            <td className="text-center">
                              <Button
                                className="btn-round btn-icon"
                                outline
                                color="success"
                                id={`tooltipEdit${index}`}
                                size="sm"
                                onClick={() => toggleAddModal(category.id)}
                              >
                                <i className="fa fa-edit" />
                              </Button>{" "}
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipEdit${index}`}
                              >
                                Edit
                              </UncontrolledTooltip>
                              <Button
                                className="btn-round btn-icon"
                                outline
                                color="danger"
                                id={`tooltipDelete${index}`}
                                size="sm"
                                onClick={() => deleteCategoryById(category.id)}
                              >
                                <i className="fa fa-times" />
                              </Button>{" "}
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipDelete${index}`}
                              >
                                Delete
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  <div className="category form-category">
                    <i
                      className="fa fa-circle"
                      style={{ color: "rgb(245 243 243)" }}
                    />
                    This color represents that the category is inActive
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      )}
      ;
      <AddCategory
        isOpen={isAddModalOpen}
        toggle={toggleAddModal}
        categoryId={categoryId}
      />
      <ImageModal isOpen={isImageModalOpen} toggle={toggleImageModal} obj={categoryModal} />
    </>
  );
}

export default CategoryList;

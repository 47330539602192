import http from "../../Environment.js";

class UserService {

  changePassword(data) {
    return http.put("/user/change-password", data);
  }

}

export default new UserService();
import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Badge,
  Button,
  CardFooter,
} from "reactstrap";
import Loader from 'components/loader/loader.js';
import OrderService from "../../../service/OrderService.js";
import { useLocation,useNavigate } from "react-router-dom";
import "../view-order/viewOrder.css";
import NotificationAlert from "react-notification-alert";
const ViewOrder = () => {

  const initialOrderState = {
    id: 0,
    orderNo: "",
    orderDate: "",
    customerName: "",
    email: "",
    customerMobile: "",
    customerAddLine1: "",
    customerAddLine2: "",
    city: "",
    pincode: "",
    state: "",
    country: "",
    status: "",
    totalAmount: "",
    totalQuantity: "",
    paymentMode: "",
    paymentReferenceId: "",
    orderComments: "",
    orderDetailsResponseList: [{
      productId: "",
      productName: "",
      productSellingRate: "",
      quantity: "",
      totalQuantityRate: "",
    }
    ],
  };

  const notificationAlert = React.useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = location.state ? location.state.orderId : 0;
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(initialOrderState);
  const isView = orderId > 0;


  useEffect(() => {
    fetchorderData();
  }, [orderId]);

  const fetchorderData = async () => {
    if (isView) {
      try {
        setIsLoading(true);
        const res = await OrderService.getById(orderId);
        const data = res.data
        setOrder({
          id: data.id,
          orderNo: data.orderNo,
          orderDate: data.orderDate,
          customerName: data.customerName,
          email:data.email,
          customerMobile: data.customerMobile,
          customerAddLine1: data.customerAddLine1,
          customerAddLine2: data.customerAddLine2,
          city: data.city,
          pincode: data.pincode,
          state: data.state,
          country: data.country,
          status: data.status,
          totalAmount: data.totalAmount,
          totalQuantity: data.totalQuantity,
          paymentMode: data.paymentMode,
          paymentReferenceId: data.paymentReferenceId,
          orderComments: data.orderComments,
          orderDetailsResponseList: data.orderDetailsResponseList,
        });
      } catch (error) {
        console.error("Error fetching category by ID:", error);
        validate(`Something went wrong`,"Please try again later");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'NEW':
        return 'primary';
      case 'IN PROGRESS':
        return 'warning';
      case 'REJECTED':
        return 'danger';
      case 'CANCELLED':
        return 'danger';
      case 'DELIVERED':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const getPaymentMode = (mode) => {
    switch (mode) {
      case 'C':
        return 'Cash On Delivery';
      case 'U':
        return 'UPI';
      case 'N':
        return 'Net Banking';
      default:
        return 'Cash On Delivery';
    }
  };
  
  const close = () => {
    navigate("/admin/order");
  }

  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
          <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          {alert}
          <Row>
            <Col md="12">
              <Card className="cards">
                <div className="invoice">
                  <div className="invoice_info">
                    <div className="i_row">
                      <div className="i_logo">
                        <h1>THE VEDIC PRODUCTS</h1>
                      </div>
                    </div>
                    <div className="i_row">

                      <div className="i_to">
                        <div className="main_title">
                          <p>Customer Details</p>
                        </div>
                        <div className="p_title">
                        <span style={{fontWeight:"bold"}}> {order.customerName},</span><br/>
                           <span className="space">{order.customerMobile},</span><br/>
                          <span className="space">{order.email},</span>
                        </div>
                        <div className="p_title">
                          <span className="space">{order.customerAddLine1},<br />
                          {order.customerAddLine2 && (
                            <>
                            {order.customerAddLine2},<br />
                            </>
                          )}
                            {order.city} - {order.pincode},<br />
                            {order.state},<br />
                            {order.country}</span>
                        </div>
                      </div>
                      <div className="i_details text_right">
                        <div className="main_title">
                          <p>Order details</p>
                        </div>
                        <div className="p_title">
                          <p>Order No:</p>
                          <span className="space">{order.orderNo}</span>
                        </div>
                        <div className="p_title">
                          <p>Order Date:</p>
                          <span className="space">{order.orderDate}</span>
                        </div>
                      </div>
                    </div>
                    <div className="i_row">
                      <div className="i_payment">
                        <div className="main_title">
                          <p>Payment Info</p>
                        </div>
                        <div className="p_title">
                          <p>Payment Mode:</p>
                          <span className="space">{getPaymentMode(order.paymentMode)}</span>
                        </div>
                        {order.paymentReferenceId && (
                        <div className="p_title">
                          <p>Payment Id:</p>
                          <span className="space">{order.paymentReferenceId}</span>
                        </div>
                        )}
                      </div>
                      <div className="i_duetotal text_right">
                        <div className="main_title">
                          <p>Order Status</p>
                        </div>
                        <div className="p_title">
                          <p>Status:</p>
                          <span><Badge color={getStatusColor(order.status)} pill>
                            {order.status}
                          </Badge></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice_table">
                    <div className="i_table">
                      <div className="i_table_head">
                        <div className="i_row">
                          <div className="i_col w_15 ">
                            <p className="p_title">S.NO</p>
                          </div>
                          <div className="i_col w_55">
                            <p className="p_title">DESCRIPTION</p>
                          </div>
                          <div className="i_col w_15 text_center">
                            <p className="p_title">QTY</p>
                          </div>
                          <div className="i_col w_15 text_center">
                            <p className="p_title">PRICE</p>
                          </div>
                          <div className="i_col w_15 text_right">
                            <p className="p_title">TOTAL</p>
                          </div>
                        </div>
                      </div>
                      <div className="i_table_body">
                        {order.orderDetailsResponseList.map((order, index) => (
                          <div key={index} className="i_row">
                            <div className="i_col w_15">
                              <p>{index + 1}</p>
                            </div>
                            <div className="i_col w_55">
                              <p>{order.productName}</p>
                            </div>
                            <div className="i_col w_15 text_center">
                              <p>{order.quantity}</p>
                            </div>
                            <div className="i_col w_15 text_center">
                              <p>{order.productSellingRate}</p>
                            </div>
                            <div className="i_col w_15 text_right">
                              <p>${order.totalQuantityRate}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="i_table_foot">
                        <div className="i_row">
                        </div>
                        <div className="i_row grand_total_wrap">
                          <div className="i_col w_50">
                            <p>GRAND TOTAL:</p>
                          </div>
                          <div className="i_col w_50 text_right">
                            <p>${order.totalAmount}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  {order.paymentReferenceId && (
                  <div className="invoice_terms">
                    <div className="main_title">
                      <p>Comments</p>
                    </div>
                    <p>{order.orderComments}</p>
                  </div>
                  )}
                </div>
                <CardFooter className="text-right">
                  <Button className="btn-round " outline color="danger" onClick={close}>
                    Close
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      )};
    </>
  );
};

export default ViewOrder;

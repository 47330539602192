/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{ useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from 'react-router-dom';
import { getAllContact, deleteContact } from "../../slices/ContactSlices.js";
import Loader from 'components/loader/loader.js';


const ContactList = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const contactList = useSelector((state) => state.contact.contactList);
  
  useEffect(() => {
    listAllContactList();
  }, [dispatch]);
  
  const listAllContactList = async () => {
    setIsLoading(true);
    await dispatch(getAllContact());
    setIsLoading(false);
  }
  
  const successDelete = async (id) => {
    setIsLoading(true);
    await dispatch(deleteContact(id));
    setIsLoading(false);
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data has been deleted.
      </ReactBSAlert>
    );
  };
  
  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data file is safe :)
      </ReactBSAlert>
    );
  };
  
  const hideAlert = () => {
    setAlert(null);
  };
  
  const deleteContactById = (id) =>{
    if(id > 0){
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(id)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this data!
        </ReactBSAlert>
      );
    }
  };
  



  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
      {alert}
        <Row>
          <Col md="12">
            <Card>
            <CardHeader>
                <Row>
                  <Col sm="7">
                  <CardTitle tag="h4">Contact Table</CardTitle>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Link to="/admin/add-contact-us" state={{ contactId: 0 }}>
                        <Button className="btn-round" color="primary" outline>
                          <i className="nc-icon nc-simple-add" />
                          Add
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Mobile Number</th>
                      <th className="text-center">Address</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {contactList.length > 0 ? (
                    contactList.map((contact, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{contact.name}</td>
                        <td className="text-center">{contact.email}</td>
                        <td className="text-center">{contact.mobileNumber}</td>
                        <td className="text-center">{contact.address}</td>
                        <td className="text-center">
                          <Badge color={contact.status === 'A' ? 'success' : 'danger'} pill>
                            {contact.status === 'A' ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td className="text-center">
                          <Link to= "/admin/add-contact-us" state= {{ contactId: contact.id }} >
                            <Button className="btn-round btn-icon" outline color="success" id={`tooltipEdit${index}`} size="sm">
                            <i className="fa fa-edit" />
                            </Button>{" "}
                          </Link>
                          <UncontrolledTooltip delay={0} target={`tooltipEdit${index}`}>
                            Edit
                          </UncontrolledTooltip>
                          <Button className="btn-round btn-icon" outline color="danger" id={`tooltipDelete${index}`} size="sm" onClick={() => deleteContactById(contact.id)}>
                            <i className="fa fa-times" />
                          </Button>{" "}
                          <UncontrolledTooltip delay={0} target={`tooltipDelete${index}`}>
                            Delete
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                     <tr>
                       <td colSpan="7" className="text-center">
                         No data Found
                       </td>
                     </tr>
                   )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      )};
    </>
  );
}

export default ContactList;

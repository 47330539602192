/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect,useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomer } from "../../slices/CustomerSlices.js";
import Loader from 'components/loader/loader.js';

function Customer() {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const customerList = useSelector((state) => state.customer.customers);

  useEffect(() => {
    listAllCustomer();
  }, [dispatch]);
  
  const listAllCustomer = async () => {
    setIsLoading(true);
    await dispatch(getAllCustomer());
    setIsLoading(false);
  }

  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
      {alert}
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                  <CardTitle tag="h4">Customer Table</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Customer Name</th>
                      <th className="text-center">Mobile Number</th>
                    </tr>
                  </thead>
                  <tbody>
                  {customerList.length > 0 ? (
                    customerList.map((customer, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{customer.customerName}</td>
                        <td className="text-center">{customer.customerMobile}</td>
                      </tr>
                    ))
                  ) : (
                     <tr>
                       <td colSpan="4" className="text-center">
                         No data Found
                       </td>
                     </tr>
                   )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      )};
    </>
  );
}

export default Customer;

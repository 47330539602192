import http from "../../Environment.js";

class CategoryService {
  getAll() {
    return http.get("/category");
  }

  getById(id) {
    return http.get(`/category/${id}`);
  }

  getImageById(id) {
    return http.get(`/category/${id}/img`,{
      responseType: 'arraybuffer',
    });;
  }

  add(data) {
    return http.post("/category", data);
  }

  update(data) {
    return http.put("/category", data);
  }

  updateStaus(data) {
    return http.put("/category/status", data);
  }

  delete(id) {
    return http.delete(`/category/${id}`);
  }
}

export default new CategoryService();
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CategoryService from "../service/CategoryService.js";


export const addCategory = createAsyncThunk(
  "addCategory",
  async (formData) => {
    try {
      const res = await CategoryService.add(formData)
      return res.data;
    }
    catch (error) {
      console.error("Error adding category:", error);
      throw error;
    }
  }
);

export const getAllCategory = createAsyncThunk(
  "getAllCategory",
  async () => {
    try {
      const res = await CategoryService.getAll();
      return res.data;
    } catch (error) {
      console.error("Error fetching all category:", error);
      throw error;
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (formData) => {
    try {
      const res = await CategoryService.update(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error updating category:", error);
      throw error;
    }
  }
);

export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (formData) => {
    try {
      const res = await CategoryService.updateStaus(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error updating status:", error);
      throw error;
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (id) => {
    try {
      await CategoryService.delete(id);
      return id;
    }
    catch (error) {
      console.error("Error deleting category:", error);
      throw error;
    }
  }
);


const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    status: 'idle',
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(getAllCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(addCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
      })

      .addCase(updateCategory.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const index = state.categories.findIndex(category => category.id === updatedCategory.id);
        if (index !== -1) {
          state.categories[index] = updatedCategory;
        }
      })

      .addCase(updateStatus.fulfilled, (state, action) => {
        const updatedCategory = action.payload;
        const index = state.categories.findIndex(category => category.id === updatedCategory.id);
        if (index !== -1) {
          state.categories[index] = updatedCategory;
        }
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        const deletedCategoryId = action.payload;
        state.categories = state.categories.filter((category) => category.id !== deletedCategoryId);
      });

  },
});

const { reducer } = categorySlice;
export default reducer;

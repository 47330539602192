/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect,useState }  from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Select from "react-select";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { addProduct,updateProduct } from "../../slices/ProductSlices.js";
import { getAllCategory } from "../../slices/CategorySlices.js";
import  ProductService  from "../../service/ProductService.js";
import { useLocation } from "react-router-dom";
import FileInputButton from "components/ImageUpload/ImageUpload.js";
import Loader from 'components/loader/loader.js';
import NotificationAlert from "react-notification-alert";

function AddProduct() {
  
  const initialProductState = {
    id: 0,
    productName: "",
    categoryId: 0,
    categoryName: "please select Category",
    actualRate: "",
    sellingRate: "",
    description: "",
    active:false,
  };

  const notificationAlert = React.useRef();
  const location = useLocation();
  const productId = location.state ? location.state.productId : 0;
  const isEdit = productId > 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null); 
  const [product, setProduct] = useState(initialProductState);
  const [submitted, setSubmitted] = useState(false);
  const categories = useSelector((state) => state.category.categories);
  const [productNameState, setProductNameState] = React.useState("");
  const [categoryIdState, setCategoryIdState] = React.useState("");
  const [sellingRateState, setSellingRateState] = React.useState("");
  const [actualRateState, setActualRateState] = React.useState("");
  const [descriptionState, setDescriptionState] = React.useState("");
  const [productImages, setProductImages] = useState([]);
  const [image, setImage] = useState(null);
  const [primaryImage, setPrimaryImage] = useState(true);
  const [productPrimaryImageState, setProductPrimaryImageState] = React.useState("");
  const [productSecondaryImage1State, setProductSecondaryImage1State] = React.useState("");
  const [productSecondaryImage2State, setProductSecondaryImage2State] = React.useState("");
  const [prevProduct, setPrevProduct] = React.useState(initialProductState);

  useEffect(() => {
    const fetchData = async () => {
      await listAllCategory();
      fetchProductData(); 
    };
    fetchData();
  }, [isEdit,productId,dispatch]);

  const fetchProductData = async () => {
    if (isEdit) {
      try {
        setIsLoading(true);
        const res = await ProductService.getById(productId);
        const data = res.data
        setProduct({
          id: data.id,
          productName:data.productName,
          categoryId: data.categoryId,
          categoryName: data.categoryName,
          sellingRate: data.sellingRate,
          actualRate: data.actualRate,
          description: data.description,
          active: data.active,
        });
        setPrevProduct({
          id: data.id,
          productName:data.productName,
          categoryId: data.categoryId,
          categoryName: data.categoryName,
          sellingRate: data.sellingRate,
          actualRate: data.actualRate,
          description: data.description,
          active: data.active,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching product by ID:", error);
        validate(`Something went wrong`,"Please try again later");
      }
    }
  };
  
  const listAllCategory = async () => {
    setIsLoading(true);
    const res = await dispatch(getAllCategory());
    if(res.error) {
      setIsLoading(false);    
      validate(`Something went wrong`,"Please try again later");
    }
    else {
    setIsLoading(false);
    }
  }
  
  const hideAlert = () => {
    clear();
    setAlert(null);
  };

  const verifyMinLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyMaxLength = (value, length) => {
    if (value.length <= length) {
      return true;
    }
    return false;
  };

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const handlePrimaryImageUpload = (e, image) => {
    if(handleImageUpload(e,image)) {
      setProductPrimaryImageState("has-success");
    }
    else{
      setProductPrimaryImageState("has-danger");
    }
  }

  const handleSecondaryImage1Upload = (e, image) => {
    if(handleImageUpload(e,image)) {
      setProductSecondaryImage1State("has-success");
    }
    else{
      setProductSecondaryImage1State("has-danger");
    }
  }

  const handleSecondaryImage2Upload = (e, image) => {
    if(handleImageUpload(e,image)) {
      setProductSecondaryImage2State("has-success");
    }
    else{
      setProductSecondaryImage2State("has-danger");
    }
  }

  const handleImageUpload = (e, primaryImage) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const maxSize = 1 * 1024 * 1024;
      let isValid = true;
      const validatedFiles = Array.from(selectedFiles).filter((file) => {
        const fileType = file.type;
        if (!allowedTypes.includes(fileType) || file.size > maxSize) {
          isValid = false;
          return false;
        }
        return true;
      });
      if (isValid) {
        const updatedProductImages = validatedFiles.map((file) => ({ primaryImage, image: file }));
        if(isEdit) {
          setImage(updatedProductImages[0].image);
          setPrimaryImage(updatedProductImages[0].primaryImage)
          return true;
        }
        else {
          setProductImages((prevImages) => [...prevImages, ...updatedProductImages]);
          return true;
        }
      } else {
        e.target.value = null;
        return false;
      }
    }
  };

  const clear = () =>{
      setProduct(initialProductState);
  }

  const submit = async () => {
    const totalImageSize = productImages.reduce((totalSize, imageObj) => {
      return totalSize + imageObj.image.size;
    }, 0);
    const isValidImageSize = totalImageSize <= 3 * 1024 * 1024;
    const isValidProductname =product.productName !==null && product.productName !== "" && product.productName !=prevProduct.productName;
    const isValidcategoryId =product.categoryId && product.categoryId !== "" && product.categoryId !=prevProduct.categoryId;
    const isValidDescription =verifyMaxLength(product.description, 1500) && product.description && product.description !== "" && product.description !=prevProduct.description;
    const isValidMrp =product.actualRate && product.actualRate !== "" && product.actualRate !=prevProduct.actualRate;
    const isValidSellingRate =product.sellingRate && product.sellingRate !== "" && product.sellingRate !=prevProduct.sellingRate;
    const isValidImages = productImages.length !== 0;
    const isValidImage = image !== null && image !== undefined && image !== "";
    if(isValidProductname || isValidDescription || isValidcategoryId || isValidMrp || isValidSellingRate ||isValidImages || isValidImage){
        if (productId > 0) {
          if(productImages.length >0) {
           if(isValidImageSize) {
             updateProductData();
           }
           else {
            validate("Alert !!","Sum of all image size less than 1mb");
           }
          }
          else {
            updateProductData();
          }
        } else  {
          if(isValidProductname){
            if(isValidcategoryId) {
              if(isValidSellingRate) {
                if(isValidMrp) {
                  if(isValidDescription) {
                    if(isValidImages) {
                      if(isValidImageSize) {
                        saveProductData();
                      }
                      else {
                        validate("Alert !!","Sum of all image size less than 1mb");
                      }
                    }
                    else {
                      setProductPrimaryImageState("has-danger");
                    }
                  }
                  else{
                    setDescriptionState("has-danger");
                  }
                }
                else {
                  setActualRateState("has-danger");
                }
              }
              else {
                setSellingRateState("has-danger");
              }
            }
            else {
              setCategoryIdState("has-danger");
            }
          }
          else{
            setProductNameState("has-danger");
          }
        }
      }
    else {
      validate("Alert !!","Please change the detail to update it");
    }
  };

 const updateProductData = async () => {
    setIsLoading(true);
    let formData = new FormData();
      formData.append("id", product.id);
      formData.append("productName", product.productName.trim());
      formData.append("categoryId", product.categoryId);
      formData.append("sellingRate", product.sellingRate);
      formData.append("actualRate", product.actualRate);
      formData.append("active", product.active);
      formData.append("description", product.description.trim());
      if(image != null && image != undefined) {
        formData.append(`primaryImage`, primaryImage);
        formData.append(`image`, image);
      }
     
      const res = await dispatch(updateProduct(formData));
      if(res.error) {
        setIsLoading(false);    
        validate(`Something went wrong`,"Please try again later");
      }
      else {
        setSubmitted(true);
        setIsLoading(false);
        showAlert("Your data has been updated successfully.");
      }
  }

  const saveProductData = async () => {
    setIsLoading(true);
    let formData = new FormData();
      formData.append("productName", product.productName.trim());
      formData.append("categoryId", product.categoryId);
      formData.append("sellingRate", product.sellingRate);
      formData.append("actualRate", product.actualRate);
      formData.append("active", product.active);
      formData.append("description", product.description.trim());
      if (productImages && productImages.length > 0) {
        productImages.forEach((imageObj, index) => {
          formData.append(`productImages[${index}].primaryImage`, imageObj.primaryImage);
          formData.append(`productImages[${index}].image`, imageObj.image);
        });
      }
    const res = await dispatch(addProduct(formData));
    if(res.error) {
      setIsLoading(false);    
      validate(`Something went wrong`,"Please try again later");
    }
    else {
      setIsLoading(false);
      setSubmitted(true);
      showAlert("Your data has been saved successfully.");
    }
  }

  const showAlert = (message) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved!"
        onConfirm={() =>{
          hideAlert(),
          navigate("/admin/product")
        } }
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const cancel = () => {
    clear();
    navigate("/admin/product");
  }
  
  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
            <b>{name}</b><br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
       <NotificationAlert ref={notificationAlert} />
     {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
      {alert}
        <Row>
          <Col md="9">
            <Form id="LoginValidation">
              <Card>  
                <CardHeader>
                  <CardTitle tag="h4">{isEdit ? "Edit" : "Add New"} Product</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup className={`has-label ${productNameState}`}>
                        <Label>Product Name *</Label>
                        <Input
                          name="fullname"
                          type="text"
                          value={product.productName}
                          onChange={(e) => {
                            if (!verifyMinLength(e.target.value, 1)) {
                              setProductNameState("has-danger");
                            } else {
                              setProductNameState("has-success");
                            }
                            setProduct({ ...product, productName: e.target.value })
                          }}
                          placeholder="Please Enter Product Name"
                        />
                        {productNameState === "has-danger" ? (
                          <label className="error">Please Enter Product Name.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-2" md="4">
                      <FormGroup className={`has-label ${categoryIdState}`}>
                        <Label>Category *</Label>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="categoryName"
                          value={
                            product.categoryId
                              ? { value: product.categoryId, label: product.categoryName }
                              : null
                          }
                          onChange={(selectedOption) => {
                            setCategoryIdState(selectedOption ? "has-success" : "has-danger");
                            const categoryId = selectedOption ? selectedOption.value : "";
                            const categoryName = selectedOption ? selectedOption.label : "";
                            setProduct({ ...product, categoryId:  categoryId, categoryName: categoryName})
                          }}
                          options={[
                            {
                              value: "",
                              label: "Category Option",
                              isDisabled: true,
                            },
                            ...categories.map((category) => ({
                              value: category.id,
                              label: category.categoryName,
                            })),
                          ]}
                          placeholder="Please Select Category"
                        /> {categoryIdState === "has-danger" ? (
                          <label className="error">Please Select Category Name.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="pl-2" md="4">
                      <FormGroup className={`has-label ${sellingRateState}`}>
                        <Label>Selling Price *</Label>
                        <Input
                          name="sellingRate"
                          type="text"
                          value={product.sellingRate}
                          onChange={(e) => {
                            if (!verifyMinLength(e.target.value, 1)) {
                              setSellingRateState("has-danger");
                            }
                            else{
                              if (!verifyNumber(e.target.value)) {
                                setSellingRateState("has-danger");
                              } else {
                                setSellingRateState("has-success");
                              }
                            }
                            setProduct({ ...product, sellingRate: e.target.value })
                          }}
                          placeholder="Please Enter Selling Price"
                        />
                        {sellingRateState === "has-danger" ? (
                          <label className="error">please Enter Valid Price.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col className="pl-2" md="4">
                      <FormGroup className={`has-label ${actualRateState}`}>
                        <Label>ActualRate *</Label>
                        <Input
                          name="actualRate"
                          type="text"
                          value={product.actualRate}
                          onChange={(e) => {
                            if (!verifyMinLength(e.target.value, 1)) {
                              setActualRateState("has-danger");
                            }
                            else{
                              if (!verifyNumber(e.target.value)) {
                                setActualRateState("has-danger");
                              } else {
                                setActualRateState("has-success");
                              }
                            }
                            setProduct({ ...product, actualRate: e.target.value })
                          }}
                          placeholder="Please Enter ActualRate"
                        />
                        {actualRateState === "has-danger" ? (
                          <label className="error">Please Enter ActualRate.</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup className={`has-label ${descriptionState}`}>
                        <Label>Description *</Label>
                        <Input
                          name="description"
                          type="textarea"
                          value={product.description}
                          onChange={(e) => {
                            if (!verifyMinLength(e.target.value, 1)) {
                              setDescriptionState("has-danger");
                            } else {
                              if(!verifyMaxLength(e.target.value,1500)) {
                                setDescriptionState("has-danger");
                              }
                              else {
                                setDescriptionState("has-success");
                              }
                            }
                            setProduct({ ...product, description: e.target.value })
                          }}
                          placeholder="Please Enter Product Description"
                        />
                        {descriptionState === "has-danger" ? (
                          <label className="error">Product Description Character Length Must be between 1 and 1500 Characters only .</label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  {isEdit ?(
                  <Row>
                    <Col>
                      <FormGroup  className={`has-label ${productPrimaryImageState}`}>
                        <Label>Primary Image *</Label>
                        <FileInputButton type="file" name="image" id="image" onChange={(e) => handlePrimaryImageUpload(e, true)} accept=".png, .jpg, .jpeg"/>
                        {productPrimaryImageState === "has-danger" ? (
                      <Label className="error">Please Upload Valid File and File Size upto 1Mb .</Label>
                    ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  ) : (
                    <>
                    
                      <Row>
                        <Col>
                          <FormGroup  className={`has-label ${productPrimaryImageState}`}>
                            <Label>Primary Image *</Label>
                            <FileInputButton type="file" name="image" id="image" onChange={(e) => handlePrimaryImageUpload(e, true)} accept=".png, .jpg, .jpeg"/>
                            {productPrimaryImageState === "has-danger" ? (
                          <Label className="error">Please Upload Valid File and File Size upto 1Mb .</Label>
                        ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup  className={`has-label ${productSecondaryImage1State}`}>
                            <Label>Secondary Images 1</Label>
                            <FileInputButton type="file" name="other_image" id="other_image" onChange={(e) => handleSecondaryImage1Upload(e, false)} accept=".png, .jpg, .jpeg"/>
                            {productSecondaryImage1State === "has-danger" ? (
                          <Label className="error">Please Upload Valid File and File Size upto 1Mb .</Label>
                        ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup  className={`has-label ${productSecondaryImage2State}`}>
                            <Label>Secondary Images 2</Label>
                            <FileInputButton type="file" name="other_image" id="other_image" onChange={(e) => handleSecondaryImage2Upload(e, false)} accept=".png, .jpg, .jpeg"/>
                            {productSecondaryImage2State === "has-danger" ? (
                          <Label className="error">Please Upload Valid File and File Size upto 1Mb .</Label>
                        ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                   )
                  }
                  <Row>
                    <Col>
                      <FormGroup check className=" ml-1">
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={product.active} 
                              onChange={() => {
                                const newStatus = !product.active;
                                setProduct({ ...product, active: newStatus });
                              }}
                            />
                            Mark As Active{" "}
                            <span className="form-check-sign" />
                          </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="category form-category">
                    * Required fields
                  </div>
                </CardBody>
                <CardFooter className="text-right">
                  <Row>
                    <Col md="12">
                      <Button className="btn-round" outline color="primary" onClick={submit}>
                      {isEdit ? "Update" : "Save"} 
                      </Button>{" "}
                        <Button className="btn-round" outline color="danger" onClick={cancel}>
                          Cancel
                        </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
      )};
    </>
  );
}

export default AddProduct;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../service/UserService";


export const changePassword = createAsyncThunk(
  "changePassword",
  async (formData) => {
    try {
      const res = await UserService.changePassword(formData)
      return res.data;
    }
    catch (error) {
      console.error("Error Changing Password:", error);
      throw error;
    }
  }
);


const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    status: 'idle',
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(changePassword.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
  },
});

const { reducer } = userSlice;
export default reducer;

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import Loader from "components/loader/loader.js";
import { changePassword } from "../../slices/UserSlices.js";
import NotificationAlert from "react-notification-alert";


const ChangePassword = ({ isOpen, toggle }) => {

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordState, setNewPasswordState] = React.useState("");
  const notificationAlert = React.useRef();

  useEffect(() => {
    
  }, []);

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const resetForm = () => {
    clear();
    setNewPasswordState("");
  };

  const close = () => {
    clear();
    resetForm();
    toggle();
  };

  const clear = () =>{
    setNewPassword("");
  }

  const submit = async () => {
    const isValidpassword = newPassword && newPassword !== "" && newPassword.length >= 8;
    if(isValidpassword){
          setIsLoading(true);
          const formData = new FormData();
          formData.append("newPassword", newPassword);
          const res = await dispatch(changePassword(formData));
          if(res.error) {
            setIsLoading(false);    
            validate(`Something went wrong`,"Please try again later");
          }
          else {
            setIsLoading(false);
            setSubmitted(true);
            showAlert("Your password has been updated successfully.");
          }
    }
    else {
      setNewPasswordState("has-danger");
    }
  };

  const showAlert = (message) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    setSubmitted(false);
    resetForm();
    toggle();
  };

  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
          <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };


  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <Modal isOpen={isOpen} toggle={toggle}>
        {alert}
        <ModalHeader toggle={toggle}>
         Change Password
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${newPasswordState}`}>
                    <Label>New Password *</Label>
                    <Input
                      name="newdPassword"
                      type="password"
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 8)) {
                            setNewPasswordState("has-danger");
                        } else {
                            setNewPasswordState("has-success");
                            setNewPassword( e.target.value)
                        }
                      }}
                      placeholder="Please Enter New Password"
                    />
                    {newPasswordState === "has-danger" ? (
                      <Label className="error">
                        Password must have 8 characters.
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <div className="category form-category">* Required fields</div>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button className="btn-round" outline color="primary" onClick={submit}>Update </Button>{" "}
          <Button className="btn-round" outline color="danger" onClick={close}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChangePassword;

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  CardTitle,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useDispatch } from "react-redux";
import { addCategory, updateCategory } from "../../slices/CategorySlices.js";
import CategoryService from "../../service/CategoryService.js";
import FileInputButton from "components/ImageUpload/ImageUpload.js";
import Loader from "components/loader/loader.js";
import NotificationAlert from "react-notification-alert";


const AddCategory = ({ isOpen, toggle, categoryId }) => {
  const initialCategoryState = {
    id: 0,
    categoryName: "",
    description: "",
    active: false,
  };

  const notificationAlert = React.useRef();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isEdit = categoryId > 0;
  const [alert, setAlert] = React.useState(null);
  const [category, setCategory] = useState(initialCategoryState);
  const [submitted, setSubmitted] = useState(false);
  const [categoryNameState, setCategoryNameState] = React.useState("");
  const [categoryImageState, setCategoryImageState] = React.useState("");
  const [categoryImage, setCategoryImage] = React.useState(null);
  const [prevCategoryName, setPrevCategoryName] = React.useState("");
  const [descriptionState, setDescriptionState] = React.useState("");
  
  useEffect(() => {
    fetchCategoryData();
  }, [isEdit, categoryId]);

  const fetchCategoryData = async () => {
    if (isEdit) {
      setIsLoading(true);
      try {
        const res = await CategoryService.getById(categoryId);
        const data = res.data;
        setCategory({
          id: data.id,
          categoryName: data.categoryName,
          description: data.description,
          active: data.active,
        });
        setPrevCategoryName(data.categoryName);
      } catch (error) {
        validate(`Something went wrong`,"Please try again later");
        console.error("Error fetching category by ID:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetForm = () => {
    clear();
    setCategoryNameState("");
    setCategoryImageState(null);
  };

  const close = () => {
    clear();
    resetForm();
    toggle();
  };

  const clear = () =>{
    setCategory(initialCategoryState);
  }

  const hideAlert = () => {
    setAlert(null);
    setSubmitted(false);
    resetForm();
    toggle();
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const verifyMaxLength = (value, length) => {
    if (value.length <= length) {
      return true;
    }
    return false;
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const fileType = selectedFile.type;
      const maxSize = 1 * 1024 * 1024; 
      if (allowedTypes.includes(fileType)) {
        if (selectedFile.size < maxSize) {
          setCategoryImage(selectedFile);
          setCategoryImageState("has-success");
        }
        else {
          setCategoryImageState("has-danger");
          e.target.value = null;
        }
      } else {
        setCategoryImageState("has-danger");
        e.target.value = null;
      }
    }
  };

  const submit = () => {
    if (categoryNameState === "has-danger") {
      setCategoryNameState("has-danger");
    } else {
      validateCategory();
    }
  };

  const validateCategory = async () => {
    const isValidCategoryname =category.categoryName && category.categoryName !== undefined && category.categoryName !== "" && category.categoryName !=prevCategoryName;
    const isValidDescription =category.description.length < 1500;
    const isValidImage = categoryImage !== null && categoryImage !== "" && categoryImage !== undefined;
    const isImageSelected = categoryImage !== null && categoryImage !== "";
    const isDescription = category.description !== null && category.description !== "";
    const maxSize = 1 * 1024 * 1024;

    if(isValidCategoryname){
      if (categoryId > 0) {
        if(isDescription) {
          if(isValidDescription) {
            if (isImageSelected) {
              if (categoryImage.size < maxSize) {
                updateCategoryData();
              }
              else {
                setCategoryImageState("has-danger");
              }
            }
            else {
              updateCategoryData();
            }
          }
          else {
            updateCategoryData();
          }
        }
        else{
          updateCategoryData();
        }

      } else  {
        if(isDescription) {
          if(isValidDescription) {
            if(isValidImage) {
            saveCategoryData();
            }
            else {
              setCategoryImageState("has-danger");
            }
          }
          else {
            if(isValidImage) {
              saveCategoryData();
              }
              else {
                setCategoryImageState("has-danger");
              }
          }
        }
        else {
          if(isValidImage) {
            saveCategoryData();
            }
            else {
              setCategoryImageState("has-danger");
            }
        }
      }
    }
    else {
      setCategoryNameState("has-danger");
    }
  };

  const updateCategoryData = async () =>{
    setIsLoading(true);
    const isImageSelected = categoryImage !== null && categoryImage !== "";
    const formData = new FormData();
    formData.append("id", category.id);
    formData.append("categoryName", category.categoryName);
    formData.append("description", category.description);
    formData.append("active", category.active);
    if(isImageSelected){
      formData.append("categoryImage", categoryImage);
    }
    const res = await dispatch(updateCategory(formData));
    if(res.error) {
      setIsLoading(false);
      validate(`Something went wrong`,"Please try again later");
    }
    else {
      setIsLoading(false);
      setSubmitted(true);
      showAlert("Your data has been updated successfully.");
    }
  }

  const saveCategoryData = async () =>{
    setIsLoading(true);
    const formData = new FormData();
    formData.append("categoryName", category.categoryName);
    formData.append("description", category.description);
    formData.append("active", category.active);
    formData.append("categoryImage", categoryImage);

    const res = await dispatch(addCategory(formData));
    if(res.error) {
      setIsLoading(false);
      validate(`Something went wrong`,"Please try again later");
    }
    else {
      setIsLoading(false);
      setSubmitted(true);
      showAlert("Your data has been saved successfully.");
    }
  }

  const showAlert = (message) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };
  
  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
            <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
    <NotificationAlert ref={notificationAlert} />
      <Modal isOpen={isOpen} toggle={toggle}>
        {alert}
        <ModalHeader toggle={toggle}>
          {isEdit ? "Edit" : "Add New"} Category
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Loader />
          ) : (
            <Form>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${categoryNameState}`}>
                    <Label>Category Name *</Label>
                    <Input
                      name="categoryName"
                      type="text"
                      value={category.categoryName}
                      onChange={(e) => {
                        if (!verifyLength(e.target.value, 1)) {
                          setCategoryNameState("has-danger");
                        } else {
                          setCategoryNameState("has-success");
                        }
                        setCategory({
                          ...category,
                          categoryName: e.target.value,
                        });
                      }}
                      placeholder="Please Enter Category Name"
                    />
                    {categoryNameState === "has-danger" ? (
                      <Label className="error">
                        Please Enter Valid Category Name.
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className={`has-label ${descriptionState}`}>Description</Label>
                    <Input
                      name="description"
                      type="textarea"
                      value={category.description}
                      onChange={(e) =>{
                        if(!verifyMaxLength(e.target.value,1500)) {
                          setDescriptionState("has-danger");
                        }
                        else {
                          setDescriptionState("has-success");
                        }
                        setCategory({
                          ...category,
                          description: e.target.value,
                        })
                      }
                      }
                      placeholder="Please Enter Description"
                    />
                     {descriptionState === "has-danger" ? (
                      <Label className="error">
                          Description Character Length Must be between 1 and 1500 Characters only
                      </Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={`has-label ${categoryImageState}`}>
                    <Label>Upload Image *</Label>
                    <FileInputButton
                      type="file"
                      name="image"
                      id="image"
                      onChange={(e) => handleImageChange(e)}
                      accept=".png, .jpg, .jpeg"
                    />
                    {categoryImageState === "has-danger" ? (
                      <Label className="error">Please Upload Valid File and File Size upto 1Mb.</Label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup check className=" ml-1">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={category.active}
                        onChange={() => {
                          const newStatus = !category.active;
                          setCategory({ ...category, active: newStatus });
                        }}
                      />
                      Mark As Active <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <div className="category form-category">* Required fields</div>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-round"
            outline
            color="primary"
            onClick={submit}
          >
            {isEdit ? "Update" : "Save"}
          </Button>{" "}
          <Button className="btn-round" outline color="danger" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCategory;

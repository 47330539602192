import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactService from "../service/ContactService.js";


export const addContact = createAsyncThunk(
  "addContact",
  async (data) => {
    try {
      const res = await ContactService.add(data);
      return res.data;
    }
    catch (error) {
      console.error("Error adding contact:", error);
      throw error;
    }
  }
);

export const getAllContact = createAsyncThunk(
  "getAllContact",
  async () => {
    try {
      const res = await ContactService.getAll();
      const response = res;
      return response.data;
    } catch (error) {
      console.error("Error fetching all contact:", error);
      throw error;
    }
  }
);

export const updateContact = createAsyncThunk(
  "updateContact",
  async (data) => {
    try {
      const res = await ContactService.update(data);
      return res.data;
    }
    catch (error) {
      console.error("Error updating contact:", error);
      throw error;
    }
  }
);

export const deleteContact = createAsyncThunk(
  "deleteContact",
  async (id) => {
    try {
      await ContactService.delete(id);
      return id;
    }
    catch (error) {
      console.error("Error deleting contact:", error);
      throw error;
    }
  }
);


const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contactList: [],
    status: 'idle',
    error: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllContact.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllContact.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contactList = action.payload;
      })
      .addCase(getAllContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      .addCase(addContact.fulfilled, (state, action) => {
        state.contactList.push(action.payload);
      })

      .addCase(updateContact.fulfilled, (state, action) => {
        const updatedContact = action.payload;
        const index = state.contactList.findIndex(contact => contact.id === updatedContact.id);
        if (index !== -1) {
          state.contactList[index] = updatedContact;
        }
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        const deletedContactId = action.payload;
        state.contactList = state.contactList.filter((contact) => contact.id !== deletedContactId);
      });

  },
});

const { reducer } = contactSlice;
export default reducer;

/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  CardFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from 'react-router-dom';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getAllProduct, deleteProduct,updateStatus } from "../../slices/ProductSlices.js";
import Loader from 'components/loader/loader.js';
import ImageModal from "components/Modal/ImageModal.js";
import NotificationAlert from "react-notification-alert";

function Product() {

  const initialProductModalState = {
    productImageResponseList: [],
    productName: "",
    tag: "P",
  };

  const notificationAlert = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const [productModal, setProductModal] = useState(initialProductModalState);
  const [isImageModalOpen, setImageModalOpen] = useState(false);

  useEffect(() => {
    listAllProducts();
  }, [dispatch]);
  
  const listAllProducts = async () => {
    setIsLoading(true);
    const res = await dispatch(getAllProduct());
    if(res.error) {
      validate(`Something went wrong`,"Please try again later");
    }
    setIsLoading(false);
  }

  const changeStatus = async (id,status) => {
    let formData = new FormData();
        formData.append("id", id);
        formData.append("activeStatus", status);
        setIsLoading(true);    
       const res = await dispatch(updateStatus(formData));
        if(res.error) {
          setIsLoading(false);    
          validate(`Something went wrong`,"Please try again later");
          hideAlert();
        }
        else {
          setIsLoading(false);    
          setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Updated!"
              onConfirm={() => hideAlerts()}
              onCancel={() => hideAlert()}
              confirmBtnBsStyle="info"
              btnSize=""
            >
              Your status has been Updated.
            </ReactBSAlert>
          );
        }
  };

  const successDelete = async (id) => {
    setIsLoading(true);
    const res = await dispatch(deleteProduct(id));
    if(res.error) {
      setIsLoading(false);
      validate(`Something went wrong`,"Please try again later");
      hideAlert();
    }
    else {
      setIsLoading(false);
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlerts()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your data has been deleted.
        </ReactBSAlert>
      );
    }
  };

  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data file is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const hideAlerts = () => {
    window.location.reload();
    setAlert(null);
  };

  const deleteProductById = (id) =>{
    if(id > 0){
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(id)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this data!
        </ReactBSAlert>
      );
    }
  };

  const statusUpdate = (id,status) => {
    if (id > 0) {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => changeStatus(id, status)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Change it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          Your status will update in list!
        </ReactBSAlert>
      );
    }
  }


  const toggleImageModal = (name, productImageResponseList) => {
    setProductModal({
      productImageResponseList: productImageResponseList,
      productName: name,
      tag: "P",
    });
    setImageModalOpen(!isImageModalOpen);
  };

  const validate = (name,message) => {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <span data-notify="icon" className="nc-icon nc-alert-circle-i"/>
          <div>
            <b>{name} !!</b> <br/> {message}
          </div>
        </div>
      ),
      type: "danger",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

 

  return (
    <>
    <NotificationAlert ref={notificationAlert} />
     {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
      {alert}
      <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                  <CardTitle tag="h4">Product Table</CardTitle>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                    <Link to="/admin/add-product" state={{ productId: 0 }}>
                    <Button className="btn-round" color="primary" outline >
                      <i className="nc-icon nc-simple-add" />
                      Add
                    </Button>
                    </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="table-full-width table-hover">
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center"></th>
                      <th className="text-center">Product Name</th>
                      <th className="text-center">Category Name</th>
                      <th className="text-center">Image</th>
                      <th className="text-center">Selling Price</th>
                      <th className="text-center">Mrp</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {products.length > 0 ? (
                      products.map((product, index) => (
                        <tr key={index} style={{ backgroundColor: product.active ? '' : 'rgb(245 243 243)' }}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                              <FormGroup check>
                                <Label check>
                                  <Input type="checkbox"
                                    checked={product.active}
                                    onChange={() =>{
                                      const newStatus = !product.active;
                                      statusUpdate(product.id,newStatus)
                                      }
                                    }
                                    />
                                  <span className="form-check-sign" />
                                </Label>
                              </FormGroup>
                            </td>
                          <td className="text-center">{product.productName}</td>
                          <td className="text-center">{product.categoryName}</td>
                          <td className="text-center"><i className="fa fa-image" onClick={() =>toggleImageModal(product.productName,product.productImageResponseList)}></i></td>
                          <td className="text-center">{product.sellingRate}</td>
                          <td className="text-center">{product.actualRate}</td>
                          <td className="text-center">
                          <Link to= "/admin/add-product" state= {{ productId: product.id }} >
                            <Button className="btn-round btn-icon" outline color="success" id={`tooltipEdit${index}`} size="sm">
                            <i className="fa fa-edit" />
                            </Button>{" "}
                          </Link>
                            <UncontrolledTooltip delay={0} target={`tooltipEdit${index}`}>
                              Edit
                            </UncontrolledTooltip>
                            <Button className="btn-round btn-icon" outline color="danger" id={`tooltipDelete${index}`} size="sm" onClick={() => deleteProductById(product.id)}>
                              <i className="fa fa-times" />
                            </Button>{" "}
                            <UncontrolledTooltip delay={0} target={`tooltipDelete${index}`} >
                              Delete
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                    <div className="category form-category">
                      <i className="fa fa-circle" style={{color:"rgb(245 243 243)"}} />
                      This color represents that the product is inActive
                    </div>
                </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    )};
      <ImageModal isOpen={isImageModalOpen} toggle={toggleImageModal} obj={productModal} />
  </>
  );
}

export default Product;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../service/OrderService.js";

export const getAllOrders = createAsyncThunk(
  "getAllOrders",
  async () => {
    try {
      const res = await OrderService.getAll();
      return res.data;
    } catch (error) {
      console.error("Error fetching all orders:", error);
      throw error;
    }
  }
);
export const getSortedOrders = createAsyncThunk(
  "getSortedOrders",
  async (data) => {
    try {
      const res = await OrderService.getAllSortBy(data);
      return res.data;
    } catch (error) {
      console.error("Error fetching all orders:", error);
      throw error;
    }
  }
);

export const getOrderStatus = createAsyncThunk(
  "getOrderStatus",
  async () => {
    try {
      const res = await OrderService.getOrderStatus();
      return res.data;
    } catch (error) {
      console.error("Error fetching all orders status:", error);
      throw error;
    }
  }
);

export const addOrders = createAsyncThunk(
  "addOrders",
  async (formData) => {
    try {
      const res = await OrderService.add(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error adding orders:", error);
      throw error;
    }
  }
);

export const updateOrders = createAsyncThunk(
  "updateOrders",
  async (formData) => {
    try {
      const res = await OrderService.update(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error adding orders:", error);
      throw error;
    }
  }
);

export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (formData) => {
    try {
      const res = await OrderService.updateStatus(formData);
      return res.data;
    }
    catch (error) {
      console.error("Error updating status:", error);
      throw error;
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders:[],
    orderStatus:[],
    status:'idle',
    error:null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
    .addCase(getAllOrders.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getAllOrders.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.orders = action.payload;
    })
    .addCase(getAllOrders.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(getSortedOrders.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getSortedOrders.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.orders = action.payload;
    })
    .addCase(getSortedOrders.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(getOrderStatus.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getOrderStatus.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.orderStatus = action.payload;
    })
    .addCase(getOrderStatus.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(addOrders.fulfilled, (state, action) => {
      state.orders.push(action.payload);
    })
    .addCase(updateOrders.fulfilled, (state, action) => {
      const updatedOrders = action.payload;
      const index = state.orders.findIndex(order => order.id === updatedOrders.id);
      if (index !== -1) {
        state.orders[index] = updatedOrders;
      }
    })
    .addCase(updateStatus.fulfilled, (state, action) => {
      const updatedOrder = action.payload;
      const index = state.orders.findIndex(order => order.id === updatedOrder.id);
      if (index !== -1) {
        state.orders[index] = updatedOrder;
      }
    })
  },
});

const { reducer } = orderSlice;
export default reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerService from "../service/CustomerService.js";

export const getAllCustomer = createAsyncThunk(
  "getAllCustomer",
  async () => {
    try {
      const res = await CustomerService.getAll();
      const response = res;
      return response.data;
    } catch (error) {
      console.error("Error fetching all customer:", error);
      throw error;
    }
  }
);


const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers:[],
    status:'idle',
    error:null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
    .addCase(getAllCustomer.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getAllCustomer.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.customers = action.payload;
    })
    .addCase(getAllCustomer.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
  
  },
});

const { reducer } = customerSlice;
export default reducer;

import { configureStore } from '@reduxjs/toolkit'
import categoryReducer from './views/slices/CategorySlices.js';
import customerReducer from './views/slices/CustomerSlices.js';
import productReducer from './views/slices/ProductSlices.js';
import contactReducer from './views/slices/ContactSlices.js';
import enquiryReducer from './views/slices/EnquirySlices.js';
import orderReducer from './views/slices/OrderSlices.js';
import dashboardReducer from './views/slices/DashboardSlices.js';
import authReducer from './views/slices/LoginSlices.js';
import userReducer from './views/slices/UserSlices.js';

const reducer = {
  customer: customerReducer,
  category: categoryReducer,
  product: productReducer,
  contact: contactReducer,
  enquiry: enquiryReducer,
  order: orderReducer,
  dashboard: dashboardReducer,
  authenticate: authReducer,
  user: userReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store;
/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ProductList from "views/components/product/ProductList";
import AddProduct from "views/components/product/AddProduct";
import Dashboard from "views/Dashboard.js";
import CategoryList from "views/components/category/CategoryList";
import OrdersList from "views/components/order/order-list/Orders";
import CustomerList from "views/components/customer/CustomerList";
import EnquiryList from "views/components/enquiry/EnquiryList";
import ContactList from "views/components/contact-us/ContactList";
import AddContact from "views/components/contact-us/AddContact";
import CompanyProfile from "views/components/profile/CompanyProfile";
import Login from "views/components/login/login";
import ViewOrder from "views/components/order/view-order/ViewOrder";

const routes = [
  
  {
    path: "/login",
    name: "Login",
    path: "/login",
    component: <Login />,
    layout: "/auth",
  },
  {
    visible:"F",
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    visible:"F",
    path: "/customer",
    name: "Customer",
    icon: "nc-icon nc-circle-10",
    component: <CustomerList />,
    layout: "/admin",
  },
  {
    visible:"F",
    path: "/category",
    name: "Category",
    icon: "nc-icon nc-book-bookmark",
    component: <CategoryList />,
    layout: "/admin",
  },
  {
    visible:"F",
    path: "/product",
    name: "Product",
    icon: "nc-icon nc-box",
    component: <ProductList />,
    layout: "/admin",
  },
  {
    visible:"F",
    path: "/order",
    name: "Orders",
    icon: "nc-icon nc-bullet-list-67",
    component: <OrdersList />,
    layout: "/admin",
  },
  // {
  //   visible:"F",
  //   path: "/enquiry",
  //   name: "Enquiry",
  //   icon: "nc-icon nc-email-85",
  //   component: <EnquiryList />,
  //   layout: "/admin",
  // },
  // {
  //   visible:"F",
  //   path: "/contact-us",
  //   name: "Contact Us",
  //   icon: "nc-icon nc-badge",
  //   component: <ContactList />,
  //   layout: "/admin",
  // },
  {
    visible:"T",
    path: "/add-product",
    component: <AddProduct />,
    layout: "/admin",
  },
  {
    visible:"T",
    path: "/add-contact-us",
    component: <AddContact />,
    layout: "/admin",
  },
  {
    visible:"T",
    path: "/company-profile",
    component: <CompanyProfile />,
    layout: "/admin",
  },
  {
    visible:"T",
    path: "/view-order",
    component: <ViewOrder />,
    layout: "/admin",
  },
  ];

export default routes;

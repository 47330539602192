/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React,{useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Link } from 'react-router-dom';
import Loader from 'components/loader/loader.js';



function CompanyProfile() {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <div className="image">
                <img
                  alt="..."
                  src={require("assets/img/bg/damir-bosnjak.jpg")}
                />
              </div>
              <CardBody>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={require("assets/img/avatar.jpg")}
                    />
                    <h5 className="title">Shafeek Rahman</h5>
                  </a>
                  <p className="description">@shafeekrahman</p>
                </div>
                <p className="description text-center">
                  "I like the way you work it <br />
                  No diggity <br />I wanna bag it up"
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card md="8">
              <CardHeader>
              <Row>
                <Col sm="7">
                <CardTitle tag="h4">Contact Info</CardTitle>
                </Col>
                <Col sm="5">
                  <div className="pull-right">
                    <Link to="/admin/add-contact-us" state={{ contactId: 0 }}>
                      <Button className="btn-round sm" color="primary" outline>
                        <span className="btn-label">
                          <i className="fa fa-edit" />
                        </span>
                        Edit
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
              </CardHeader>
              <CardBody>
                <div className="typography-line">
                  <p>
                    <span>Name </span>
                    The Vedic Products
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Email Id</span>
                    contact@thevedicfoods.com
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Mobile Number</span>
                    +91 1234567890
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Address</span>
                    123 ,Main Road ,T Nagar ,Chennai
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>Pincode</span>
                    627005
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>District</span>
                    Chennai
                  </p>
                </div>
                <div className="typography-line">
                  <p>
                    <span>State</span>
                    TamilNadu
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      )};
    </>
  );
}

export default CompanyProfile;

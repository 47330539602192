// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-drop{
 z-index: 1000;
 width: 63%;
}
/* Add this to your existing stylesheet or create a new one */
.center-table  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height based on your layout */
  }
  
  /* Apply the center-table class to the ReactTable component */
  .ReactTable {
    width: 100%; /* Adjust the width based on your layout */
    margin: 0 auto; /* Center the table horizontally */
  }
  
  /* Optional: Adjust the width of the table columns for better appearance */
  .ReactTable .rt-th,
  .ReactTable .rt-td {
    text-align: center;
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }
`, "",{"version":3,"sources":["webpack://./src/views/components/order/order-list/Order.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,UAAU;AACX;AACA,6DAA6D;AAC7D;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAE,2CAA2C;EAC3D;;EAEA,6DAA6D;EAC7D;IACE,WAAW,EAAE,0CAA0C;IACvD,cAAc,EAAE,kCAAkC;EACpD;;EAEA,0EAA0E;EAC1E;;IAEE,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".custom-drop{\r\n z-index: 1000;\r\n width: 63%;\r\n}\r\n/* Add this to your existing stylesheet or create a new one */\r\n.center-table  {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100%; /* Adjust the height based on your layout */\r\n  }\r\n  \r\n  /* Apply the center-table class to the ReactTable component */\r\n  .ReactTable {\r\n    width: 100%; /* Adjust the width based on your layout */\r\n    margin: 0 auto; /* Center the table horizontally */\r\n  }\r\n  \r\n  /* Optional: Adjust the width of the table columns for better appearance */\r\n  .ReactTable .rt-th,\r\n  .ReactTable .rt-td {\r\n    text-align: center;\r\n  }\r\n  .center {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100%;\r\n    text-align: center;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

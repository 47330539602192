import axios from "axios";
import baseUrl from "BaseUrl";

class AuthService {

  
  
  login(data) {
      const http =  axios.create({
      baseURL: `${baseUrl}/authenticate/`,
    });
    return http.post("/admin", data);
  }
  
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("status");
  };
}

export default new AuthService();
import http from "../../Environment.js";

class ProductService {
  getAll() {
    return http.get("/product");
  }

  getAllActive() {
    return http.get("/product/active");
  }

  getById(id) {
    return http.get(`/product/${id}`);
  }

  getImageById(id) {
    return http.get(`/product/${id}/img`,{
      responseType: 'arraybuffer',
    });;
  }

  add(data) {
    return http.post("/product", data);
  }

  update(data) {
    return http.put("/product", data);
  }

  updateStatus(data) {
    return http.put("/product/status", data);
  }
  
  delete(id) {
    return http.delete(`/product/${id}`);
  }
}

export default new ProductService();
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EnquiryService from "../service/EnquirySerice.js";

export const getAllEnquiry = createAsyncThunk(
  "getAllEnquiry",
  async () => {
    try {
      const res = await EnquiryService.getAll();
      const response = res;
      return response.data;
    } catch (error) {
      console.error("Error fetching all enquiry:", error);
      throw error;
    }
  }
);

export const deleteEnquiry = createAsyncThunk(
  "deleteEnquiry",
  async (id) => {
    try{
      await EnquiryService.delete(id);
      return  id;
    }
    catch (error) {
      console.error("Error deleting enquiry:", error);
      throw error;
    }
  }
);


const enquirySlice = createSlice({
  name: "enquiry",
  initialState: {
    enquirys:[],
    status:'idle',
    error:null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
    .addCase(getAllEnquiry.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(getAllEnquiry.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.enquirys = action.payload;
    })
    .addCase(getAllEnquiry.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    })
    .addCase(deleteEnquiry.fulfilled, (state, action) => {
      const deletedEnquiryId = action.payload;
      state.enquirys = state.enquirys.filter((enquiry) => enquiry.id !== deletedEnquiryId);
    });
  
  },
});

const { reducer } = enquirySlice;
export default reducer;

/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { getAllEnquiry, deleteEnquiry } from "../../slices/EnquirySlices.js";
import Loader from 'components/loader/loader.js';

const EnquiryList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const enquiryList = useSelector((state) => state.enquiry.enquirys);

  useEffect(() => {
    listAllEnquirys();
  }, [dispatch]);
  
  const listAllEnquirys = async () => {
    setIsLoading(true);
    await dispatch(getAllEnquiry());
    setIsLoading(false);
  }

  const successDelete = async (id) => {
    setIsLoading(true);
    await dispatch(deleteEnquiry(id));
    setIsLoading(false);
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDetele = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your data is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const deleteEnquiryById = (id) =>{
    if(id > 0){
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => successDelete(id)}
          onCancel={() => cancelDetele()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this data!
        </ReactBSAlert>
      );
    }
  };
  return (
    <>
    {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
        {alert}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Enquiry Table</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Email</th>
                        <th className="text-center">Mobile Number</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enquiryList.length > 0 ? (
                        enquiryList.map((enquiry, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{enquiry.name}</td>
                            <td className="text-center">{enquiry.email}</td>
                            <td className="text-center">{enquiry.mobileNumber}</td>
                            <td className="text-center">
                              <Button className="btn-round btn-icon" outline color="danger" id={`tooltipDelete${index}`} size="sm" onClick={() => deleteEnquiryById(enquiry.id)}>
                                <i className="fa fa-times" />
                              </Button>{" "}
                              <UncontrolledTooltip delay={0} target={`tooltipDelete${index}`}>
                                Delete
                              </UncontrolledTooltip>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No data Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )};
    </>
  );
}

export default EnquiryList;

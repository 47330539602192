import http from "../../Environment.js";

class OrderService {
  getAll() {
    return http.get("/orders");
  }

  getAllSortBy(orderBy) {
    return http.get("/orders", { params: { orderBy} });
  }
  
  getOrderStatus() {
    return http.get("/orders/status-list");
  }

  getById(id) {
    return http.get(`/orders/${id}`);
  }

  add(data) {
    return http.post("/orders", data);
  }

  update(data) {
    return http.put("/orders", data);
  }

  updateStatus(data) {
    return http.put("/orders/status",data);
  }
}

export default new OrderService();